


















































import { Vue, Component, Prop } from "vue-property-decorator";
import { MobileProfileProperties } from "./MobileProfileView.vue";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import { EditorControlProperties } from "@/components/shared/EditorControlView.vue";
import EditorControlView from "@/components/shared/EditorControlView.vue";
import ExceptionUtil from "@/utilities/ExceptionUtil";
import ProfileService from "@/services/ProfileService";

@Component({
    components: { EditorControlView },
    data: () => ({
        user: {},
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
        saving: false,
        visibleCurrentPassword: false,
        visibleNewPassword: false,
        visibleConfirmPassword: false
    }),
})
export default class ProfileChangePasswordView extends Vue {
    @Prop() private properties: MobileProfileProperties;
    @Prop() private alertDialog: AlertDialogProperties;
    private editorControl = new EditorControlProperties();
    private profileService = new ProfileService();

    public created() {
        this.editorControl.createVisible = false;
        this.editorControl.cancelVisible = false;
        this.editorControl.deleteVisible = false;
    }

    public async save() {
        try {
            const user = this.properties.user;
            const currentPassword = this.$data.currentPassword;
            const newPassword = this.$data.newPassword;
            const confirmPassword = this.$data.confirmPassword;

            this.$data.currentPassword = "";
            this.$data.newPassword = "";
            this.$data.confirmPassword = "";
            this.$data.visibleCurrentPassword = false;
            this.$data.visibleNewPassword = false;
            this.$data.visibleConfirmPassword = false;

            await this.profileService.changePassword(user, currentPassword, newPassword, confirmPassword);
            AlertDialogProperties.saved(this.alertDialog, null, null);
            this.alertDialog.visible = true;
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        }
    }

    public async control(button: string) {
        if (button === "save") {
            await this.save();
        }
    }
}
